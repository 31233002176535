html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}
body {
  position: relative;
  max-width: 100%;
  overflow-x: hidden;
}
.landing-wrapper,
section {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 0.3s ease-in;
  transform: scale(0.7);
  display: none;
  z-index: 10;
}
.landing-wrapper.show,
section.show {
  transform: none;
  opacity: 1;
  display: block;
}
.landing-wrapper {
  overflow: hidden;
}
.animate-up {
  opacity: 0;
}
.animate-up.show-animation {
  opacity: 1;
  animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}
.view {
  margin: 0 20px;
}
@media screen and (min-width: 767px) {
  .view {
    margin: 0 40px;
  }
}
@media screen and (min-width: 1365px) {
  .view {
    margin: 0 100px;
  }
}
@media screen and (min-width: 1839px) {
  .view {
    min-height: 100vh;
    margin: 0 200px;
  }
}
.button {
  background: #fff;
  height: 48px;
  padding: 0 20px;
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: bold;
  line-height: 140%;
  text-decoration: none;
  display: inline-block;
  align-items: center;
  cursor: pointer;
  color: #000;
  font-size: 16px;
  width: 100%;
  border: none;
}
@media screen and (min-width: 767px) {
  .button {
    font-size: 20px;
    height: 64px;
    padding: 0 30px;
    width: initial;
  }
}
@media screen and (min-width: 1365px) {
  .button {
    font-size: 24px;
  }
}
@media screen and (min-width: 1839px) {
  .button {
    height: 74px;
    padding: 0 40px;
  }
}
.button span {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}
@media screen and (min-width: 767px) {
  .button span {
    justify-content: inherit;
  }
}
.button.gl {
  position: relative;
  z-index: 3;
}
.button.active {
  background: transparent;
}
.button.active .icon:after {
  transform: translate3d(4px, 0, 0);
}
.button--outline {
  background: none;
  border: 1px solid #fff;
  color: #fff;
}
.icon:after {
  content: '';
  transition: transform 0.16s ease-in-out;
}
.icon.arrow:after {
  margin-left: 10px;
  background: url("44165848b2445738b11f7fd6ade7741f.svg");
  width: 27px;
  height: 20px;
  background-size: 27px;
  background-repeat: no-repeat;
}
@-moz-keyframes zoomIn {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: matrix(1, 0, 0, 1, 0, 20);
  }
  100% {
    opacity: 1;
    visibility: inherit;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: matrix(1, 0, 0, 1, 0, 20);
  }
  100% {
    opacity: 1;
    visibility: inherit;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
@-o-keyframes zoomIn {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: matrix(1, 0, 0, 1, 0, 20);
  }
  100% {
    opacity: 1;
    visibility: inherit;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: matrix(1, 0, 0, 1, 0, 20);
  }
  100% {
    opacity: 1;
    visibility: inherit;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
@-moz-keyframes slide-in-blurred-bottom {
  0% {
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: translateY(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
@-webkit-keyframes slide-in-blurred-bottom {
  0% {
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: translateY(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
@-o-keyframes slide-in-blurred-bottom {
  0% {
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: translateY(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
@keyframes slide-in-blurred-bottom {
  0% {
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: translateY(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
@font-face {
  font-family: 'Consolas';
  src: local('Consolas'), url("132c54f879712515cccaa101ae95c3ab.woff2") format('woff2'), url("d3f0ff506a2664889badeeaab8828b29.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Consolas';
  src: local('Consolas Bold'), local('Consolas-Bold'), url("4ccfc6eb3f15aceb9fb87d4e30ad86ee.woff2") format('woff2'), url("d9f864c37e759209b1d7bb135a40b335.woff") format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Consolas';
  src: local('Consolas Italic'), local('Consolas-Italic'), url("9b717d8ac0293c582974d00d716b8620.woff2") format('woff2'), url("350ef3227d7c2955c91d85b364d89cc6.woff") format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Gotham Pro';
  src: local('Gotham Pro Bold'), local('GothamPro-Bold'), url("9cb94c630af24bf0d1ec9d85d54209e8.woff") format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Pro';
  src: local('Gotham Pro'), local('GothamPro'), url("39ffa5070ee485693082a52c61a388a0.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
#intro.show {
  display: flex;
  align-items: center;
}
#intro:before {
  position: absolute;
  z-index: 999999;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: bg-move 2s linear infinite;
  background-size: 100% 8px;
  background-image: linear-gradient(0, rgba(255,255,255,0.05) 10%, transparent 10%, transparent 50%, rgba(255,255,255,0.05) 50%, rgba(255,255,255,0.05) 60%, transparent 60%, transparent);
}
#intro .container {
  display: flex;
  max-width: 988px;
  height: 100vh;
  margin: auto;
  padding: 0 20px;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
#intro .logo {
  position: relative;
  width: 100%;
}
@media screen and (min-width: 767px) {
  #intro .logo {
    width: inherit;
  }
}
#intro .logo svg {
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: 0.6;
}
@media screen and (min-width: 767px) {
  #intro .logo svg {
    height: 324px;
  }
}
#intro .logo svg:last-child {
  position: relative;
  transform: skewX(0deg) scaleY(1);
  animation: clock-bag 4s linear infinite;
}
#intro .logo svg:nth-child(1) {
  animation: c2 1s infinite linear alternate-reverse;
}
#intro .logo svg:nth-child(1) > * {
  fill: #d90115;
}
#intro .logo svg:nth-child(2) {
  animation: c1 2s infinite linear alternate-reverse;
}
#intro .logo svg:nth-child(2) > * {
  fill: #055175;
}
.loader {
  margin-top: 80px;
  width: 100%;
}
.info {
  display: flex;
  font-size: 18px;
  margin-bottom: 14px;
  justify-content: space-between;
}
.progress {
  position: relative;
  height: 18px;
  width: 100%;
}
.progress:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 16px;
  border: 1px solid #fff;
}
.progress-bg {
  position: relative;
  height: 100%;
  background-color: #1c17ff;
  transition: width 0.3s;
}
@-moz-keyframes bg-move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -32px;
  }
}
@-webkit-keyframes bg-move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -32px;
  }
}
@-o-keyframes bg-move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -32px;
  }
}
@keyframes bg-move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -32px;
  }
}
@-moz-keyframes clock-bag {
  0% {
    transform: translate(2px, 5px);
  }
  2% {
    transform: translate(4px, 1px);
  }
  4% {
    transform: translate(3px, 5px);
  }
  6% {
    transform: translate(2px, 5px);
  }
  8% {
    transform: translate(3px, 2px);
  }
  10% {
    transform: translate(2px, 4px);
  }
  12% {
    transform: translate(2px, 3px);
  }
  14.000000000000002% {
    transform: translate(4px, 3px);
  }
  16% {
    transform: translate(2px, 1px);
  }
  18% {
    transform: translate(3px, 2px);
  }
  20% {
    transform: translate(4px, 3px);
  }
  22% {
    transform: translate(3px, 3px);
  }
  24% {
    transform: translate(2px, 5px);
  }
  26% {
    transform: translate(5px, 5px);
  }
  28.000000000000004% {
    transform: translate(1px, 3px);
  }
  30% {
    transform: translate(3px, 5px);
  }
  32% {
    transform: translate(1px, 2px);
  }
  34% {
    transform: translate(1px, 1px);
  }
  36% {
    transform: translate(2px, 5px);
  }
  38% {
    transform: translate(4px, 3px);
  }
  40% {
    transform: translate(5px, 4px);
  }
  42% {
    transform: translate(2px, 5px);
  }
  44% {
    transform: translate(3px, 3px);
  }
  46.00000000000001% {
    transform: translate(1px, 4px);
  }
  48% {
    transform: translate(1px, 3px);
  }
  50% {
    transform: translate(2px, 5px);
  }
  52% {
    transform: translate(4px, 1px);
  }
  54% {
    transform: translate(3px, 1px);
  }
  56.00000000000001% {
    transform: translate(2px, 2px);
  }
  58% {
    transform: translate(5px, 3px);
  }
  60% {
    transform: translate(2px, 3px);
  }
  62% {
    transform: translate(5px, 1px);
  }
  64% {
    transform: translate(3px, 5px);
  }
  66% {
    transform: translate(4px, 4px);
  }
  68% {
    transform: translate(2px, 4px);
  }
  70.00000000000001% {
    transform: translate(1px, 5px);
  }
  72% {
    transform: translate(2px, 1px);
  }
  74% {
    transform: translate(2px, 2px);
  }
  76% {
    transform: translate(3px, 2px);
  }
  78% {
    transform: translate(3px, 5px);
  }
  80% {
    transform: translate(3px, 5px);
  }
  82.00000000000001% {
    transform: translate(4px, 2px);
  }
  84% {
    transform: translate(2px, 5px);
  }
  86% {
    transform: translate(4px, 1px);
  }
  88% {
    transform: translate(1px, 4px);
  }
  90% {
    transform: translate(2px, 3px);
  }
  92.00000000000001% {
    transform: translate(4px, 3px);
  }
  94% {
    transform: translate(3px, 4px);
  }
  96% {
    transform: translate(2px, 4px);
  }
  98% {
    transform: translate(1px, 4px);
  }
  100% {
    transform: translate(4px, 2px);
  }
  1% {
    transform: scaleY(1) skewX(0deg);
  }
  1.5% {
    transform: scaleY(3) skewX(-60deg);
  }
  2% {
    transform: scaleY(1) skewX(0deg);
  }
  51% {
    transform: scaleX(1) scaleY(1) skewX(0deg);
  }
  52% {
    transform: scaleX(1.5) scaleY(0.2) skewX(80deg);
  }
  53% {
    transform: scaleX(1) scaleY(1) skewX(0deg);
  }
}
@-webkit-keyframes clock-bag {
  0% {
    transform: translate(2px, 5px);
  }
  2% {
    transform: translate(4px, 1px);
  }
  4% {
    transform: translate(3px, 5px);
  }
  6% {
    transform: translate(2px, 5px);
  }
  8% {
    transform: translate(3px, 2px);
  }
  10% {
    transform: translate(2px, 4px);
  }
  12% {
    transform: translate(2px, 3px);
  }
  14.000000000000002% {
    transform: translate(4px, 3px);
  }
  16% {
    transform: translate(2px, 1px);
  }
  18% {
    transform: translate(3px, 2px);
  }
  20% {
    transform: translate(4px, 3px);
  }
  22% {
    transform: translate(3px, 3px);
  }
  24% {
    transform: translate(2px, 5px);
  }
  26% {
    transform: translate(5px, 5px);
  }
  28.000000000000004% {
    transform: translate(1px, 3px);
  }
  30% {
    transform: translate(3px, 5px);
  }
  32% {
    transform: translate(1px, 2px);
  }
  34% {
    transform: translate(1px, 1px);
  }
  36% {
    transform: translate(2px, 5px);
  }
  38% {
    transform: translate(4px, 3px);
  }
  40% {
    transform: translate(5px, 4px);
  }
  42% {
    transform: translate(2px, 5px);
  }
  44% {
    transform: translate(3px, 3px);
  }
  46.00000000000001% {
    transform: translate(1px, 4px);
  }
  48% {
    transform: translate(1px, 3px);
  }
  50% {
    transform: translate(2px, 5px);
  }
  52% {
    transform: translate(4px, 1px);
  }
  54% {
    transform: translate(3px, 1px);
  }
  56.00000000000001% {
    transform: translate(2px, 2px);
  }
  58% {
    transform: translate(5px, 3px);
  }
  60% {
    transform: translate(2px, 3px);
  }
  62% {
    transform: translate(5px, 1px);
  }
  64% {
    transform: translate(3px, 5px);
  }
  66% {
    transform: translate(4px, 4px);
  }
  68% {
    transform: translate(2px, 4px);
  }
  70.00000000000001% {
    transform: translate(1px, 5px);
  }
  72% {
    transform: translate(2px, 1px);
  }
  74% {
    transform: translate(2px, 2px);
  }
  76% {
    transform: translate(3px, 2px);
  }
  78% {
    transform: translate(3px, 5px);
  }
  80% {
    transform: translate(3px, 5px);
  }
  82.00000000000001% {
    transform: translate(4px, 2px);
  }
  84% {
    transform: translate(2px, 5px);
  }
  86% {
    transform: translate(4px, 1px);
  }
  88% {
    transform: translate(1px, 4px);
  }
  90% {
    transform: translate(2px, 3px);
  }
  92.00000000000001% {
    transform: translate(4px, 3px);
  }
  94% {
    transform: translate(3px, 4px);
  }
  96% {
    transform: translate(2px, 4px);
  }
  98% {
    transform: translate(1px, 4px);
  }
  100% {
    transform: translate(4px, 2px);
  }
  1% {
    transform: scaleY(1) skewX(0deg);
  }
  1.5% {
    transform: scaleY(3) skewX(-60deg);
  }
  2% {
    transform: scaleY(1) skewX(0deg);
  }
  51% {
    transform: scaleX(1) scaleY(1) skewX(0deg);
  }
  52% {
    transform: scaleX(1.5) scaleY(0.2) skewX(80deg);
  }
  53% {
    transform: scaleX(1) scaleY(1) skewX(0deg);
  }
}
@-o-keyframes clock-bag {
  0% {
    transform: translate(2px, 5px);
  }
  2% {
    transform: translate(4px, 1px);
  }
  4% {
    transform: translate(3px, 5px);
  }
  6% {
    transform: translate(2px, 5px);
  }
  8% {
    transform: translate(3px, 2px);
  }
  10% {
    transform: translate(2px, 4px);
  }
  12% {
    transform: translate(2px, 3px);
  }
  14.000000000000002% {
    transform: translate(4px, 3px);
  }
  16% {
    transform: translate(2px, 1px);
  }
  18% {
    transform: translate(3px, 2px);
  }
  20% {
    transform: translate(4px, 3px);
  }
  22% {
    transform: translate(3px, 3px);
  }
  24% {
    transform: translate(2px, 5px);
  }
  26% {
    transform: translate(5px, 5px);
  }
  28.000000000000004% {
    transform: translate(1px, 3px);
  }
  30% {
    transform: translate(3px, 5px);
  }
  32% {
    transform: translate(1px, 2px);
  }
  34% {
    transform: translate(1px, 1px);
  }
  36% {
    transform: translate(2px, 5px);
  }
  38% {
    transform: translate(4px, 3px);
  }
  40% {
    transform: translate(5px, 4px);
  }
  42% {
    transform: translate(2px, 5px);
  }
  44% {
    transform: translate(3px, 3px);
  }
  46.00000000000001% {
    transform: translate(1px, 4px);
  }
  48% {
    transform: translate(1px, 3px);
  }
  50% {
    transform: translate(2px, 5px);
  }
  52% {
    transform: translate(4px, 1px);
  }
  54% {
    transform: translate(3px, 1px);
  }
  56.00000000000001% {
    transform: translate(2px, 2px);
  }
  58% {
    transform: translate(5px, 3px);
  }
  60% {
    transform: translate(2px, 3px);
  }
  62% {
    transform: translate(5px, 1px);
  }
  64% {
    transform: translate(3px, 5px);
  }
  66% {
    transform: translate(4px, 4px);
  }
  68% {
    transform: translate(2px, 4px);
  }
  70.00000000000001% {
    transform: translate(1px, 5px);
  }
  72% {
    transform: translate(2px, 1px);
  }
  74% {
    transform: translate(2px, 2px);
  }
  76% {
    transform: translate(3px, 2px);
  }
  78% {
    transform: translate(3px, 5px);
  }
  80% {
    transform: translate(3px, 5px);
  }
  82.00000000000001% {
    transform: translate(4px, 2px);
  }
  84% {
    transform: translate(2px, 5px);
  }
  86% {
    transform: translate(4px, 1px);
  }
  88% {
    transform: translate(1px, 4px);
  }
  90% {
    transform: translate(2px, 3px);
  }
  92.00000000000001% {
    transform: translate(4px, 3px);
  }
  94% {
    transform: translate(3px, 4px);
  }
  96% {
    transform: translate(2px, 4px);
  }
  98% {
    transform: translate(1px, 4px);
  }
  100% {
    transform: translate(4px, 2px);
  }
  1% {
    transform: scaleY(1) skewX(0deg);
  }
  1.5% {
    transform: scaleY(3) skewX(-60deg);
  }
  2% {
    transform: scaleY(1) skewX(0deg);
  }
  51% {
    transform: scaleX(1) scaleY(1) skewX(0deg);
  }
  52% {
    transform: scaleX(1.5) scaleY(0.2) skewX(80deg);
  }
  53% {
    transform: scaleX(1) scaleY(1) skewX(0deg);
  }
}
@keyframes clock-bag {
  0% {
    transform: translate(2px, 5px);
  }
  2% {
    transform: translate(4px, 1px);
  }
  4% {
    transform: translate(3px, 5px);
  }
  6% {
    transform: translate(2px, 5px);
  }
  8% {
    transform: translate(3px, 2px);
  }
  10% {
    transform: translate(2px, 4px);
  }
  12% {
    transform: translate(2px, 3px);
  }
  14.000000000000002% {
    transform: translate(4px, 3px);
  }
  16% {
    transform: translate(2px, 1px);
  }
  18% {
    transform: translate(3px, 2px);
  }
  20% {
    transform: translate(4px, 3px);
  }
  22% {
    transform: translate(3px, 3px);
  }
  24% {
    transform: translate(2px, 5px);
  }
  26% {
    transform: translate(5px, 5px);
  }
  28.000000000000004% {
    transform: translate(1px, 3px);
  }
  30% {
    transform: translate(3px, 5px);
  }
  32% {
    transform: translate(1px, 2px);
  }
  34% {
    transform: translate(1px, 1px);
  }
  36% {
    transform: translate(2px, 5px);
  }
  38% {
    transform: translate(4px, 3px);
  }
  40% {
    transform: translate(5px, 4px);
  }
  42% {
    transform: translate(2px, 5px);
  }
  44% {
    transform: translate(3px, 3px);
  }
  46.00000000000001% {
    transform: translate(1px, 4px);
  }
  48% {
    transform: translate(1px, 3px);
  }
  50% {
    transform: translate(2px, 5px);
  }
  52% {
    transform: translate(4px, 1px);
  }
  54% {
    transform: translate(3px, 1px);
  }
  56.00000000000001% {
    transform: translate(2px, 2px);
  }
  58% {
    transform: translate(5px, 3px);
  }
  60% {
    transform: translate(2px, 3px);
  }
  62% {
    transform: translate(5px, 1px);
  }
  64% {
    transform: translate(3px, 5px);
  }
  66% {
    transform: translate(4px, 4px);
  }
  68% {
    transform: translate(2px, 4px);
  }
  70.00000000000001% {
    transform: translate(1px, 5px);
  }
  72% {
    transform: translate(2px, 1px);
  }
  74% {
    transform: translate(2px, 2px);
  }
  76% {
    transform: translate(3px, 2px);
  }
  78% {
    transform: translate(3px, 5px);
  }
  80% {
    transform: translate(3px, 5px);
  }
  82.00000000000001% {
    transform: translate(4px, 2px);
  }
  84% {
    transform: translate(2px, 5px);
  }
  86% {
    transform: translate(4px, 1px);
  }
  88% {
    transform: translate(1px, 4px);
  }
  90% {
    transform: translate(2px, 3px);
  }
  92.00000000000001% {
    transform: translate(4px, 3px);
  }
  94% {
    transform: translate(3px, 4px);
  }
  96% {
    transform: translate(2px, 4px);
  }
  98% {
    transform: translate(1px, 4px);
  }
  100% {
    transform: translate(4px, 2px);
  }
  1% {
    transform: scaleY(1) skewX(0deg);
  }
  1.5% {
    transform: scaleY(3) skewX(-60deg);
  }
  2% {
    transform: scaleY(1) skewX(0deg);
  }
  51% {
    transform: scaleX(1) scaleY(1) skewX(0deg);
  }
  52% {
    transform: scaleX(1.5) scaleY(0.2) skewX(80deg);
  }
  53% {
    transform: scaleX(1) scaleY(1) skewX(0deg);
  }
}
@-moz-keyframes c1 {
  0% {
    clip: rect(47px, 9999px, 52px, 0);
  }
  5% {
    clip: rect(132px, 9999px, 189px, 0);
  }
  10% {
    clip: rect(176px, 9999px, 43px, 0);
  }
  15.000000000000002% {
    clip: rect(193px, 9999px, 163px, 0);
  }
  20% {
    clip: rect(304px, 9999px, 122px, 0);
  }
  25% {
    clip: rect(276px, 9999px, 153px, 0);
  }
  30.000000000000004% {
    clip: rect(207px, 9999px, 35px, 0);
  }
  35.00000000000001% {
    clip: rect(311px, 9999px, 152px, 0);
  }
  40% {
    clip: rect(301px, 9999px, 82px, 0);
  }
  45% {
    clip: rect(263px, 9999px, 37px, 0);
  }
  50% {
    clip: rect(300px, 9999px, 151px, 0);
  }
  55% {
    clip: rect(283px, 9999px, 9px, 0);
  }
  60.00000000000001% {
    clip: rect(133px, 9999px, 201px, 0);
  }
  65% {
    clip: rect(216px, 9999px, 211px, 0);
  }
  70.00000000000001% {
    clip: rect(124px, 9999px, 315px, 0);
  }
  75% {
    clip: rect(266px, 9999px, 287px, 0);
  }
  80% {
    clip: rect(281px, 9999px, 203px, 0);
  }
  85% {
    clip: rect(154px, 9999px, 182px, 0);
  }
  90% {
    clip: rect(265px, 9999px, 187px, 0);
  }
  95% {
    clip: rect(38px, 9999px, 64px, 0);
  }
  100% {
    clip: rect(272px, 9999px, 248px, 0);
  }
}
@-webkit-keyframes c1 {
  0% {
    clip: rect(47px, 9999px, 52px, 0);
  }
  5% {
    clip: rect(132px, 9999px, 189px, 0);
  }
  10% {
    clip: rect(176px, 9999px, 43px, 0);
  }
  15.000000000000002% {
    clip: rect(193px, 9999px, 163px, 0);
  }
  20% {
    clip: rect(304px, 9999px, 122px, 0);
  }
  25% {
    clip: rect(276px, 9999px, 153px, 0);
  }
  30.000000000000004% {
    clip: rect(207px, 9999px, 35px, 0);
  }
  35.00000000000001% {
    clip: rect(311px, 9999px, 152px, 0);
  }
  40% {
    clip: rect(301px, 9999px, 82px, 0);
  }
  45% {
    clip: rect(263px, 9999px, 37px, 0);
  }
  50% {
    clip: rect(300px, 9999px, 151px, 0);
  }
  55% {
    clip: rect(283px, 9999px, 9px, 0);
  }
  60.00000000000001% {
    clip: rect(133px, 9999px, 201px, 0);
  }
  65% {
    clip: rect(216px, 9999px, 211px, 0);
  }
  70.00000000000001% {
    clip: rect(124px, 9999px, 315px, 0);
  }
  75% {
    clip: rect(266px, 9999px, 287px, 0);
  }
  80% {
    clip: rect(281px, 9999px, 203px, 0);
  }
  85% {
    clip: rect(154px, 9999px, 182px, 0);
  }
  90% {
    clip: rect(265px, 9999px, 187px, 0);
  }
  95% {
    clip: rect(38px, 9999px, 64px, 0);
  }
  100% {
    clip: rect(272px, 9999px, 248px, 0);
  }
}
@-o-keyframes c1 {
  0% {
    clip: rect(47px, 9999px, 52px, 0);
  }
  5% {
    clip: rect(132px, 9999px, 189px, 0);
  }
  10% {
    clip: rect(176px, 9999px, 43px, 0);
  }
  15.000000000000002% {
    clip: rect(193px, 9999px, 163px, 0);
  }
  20% {
    clip: rect(304px, 9999px, 122px, 0);
  }
  25% {
    clip: rect(276px, 9999px, 153px, 0);
  }
  30.000000000000004% {
    clip: rect(207px, 9999px, 35px, 0);
  }
  35.00000000000001% {
    clip: rect(311px, 9999px, 152px, 0);
  }
  40% {
    clip: rect(301px, 9999px, 82px, 0);
  }
  45% {
    clip: rect(263px, 9999px, 37px, 0);
  }
  50% {
    clip: rect(300px, 9999px, 151px, 0);
  }
  55% {
    clip: rect(283px, 9999px, 9px, 0);
  }
  60.00000000000001% {
    clip: rect(133px, 9999px, 201px, 0);
  }
  65% {
    clip: rect(216px, 9999px, 211px, 0);
  }
  70.00000000000001% {
    clip: rect(124px, 9999px, 315px, 0);
  }
  75% {
    clip: rect(266px, 9999px, 287px, 0);
  }
  80% {
    clip: rect(281px, 9999px, 203px, 0);
  }
  85% {
    clip: rect(154px, 9999px, 182px, 0);
  }
  90% {
    clip: rect(265px, 9999px, 187px, 0);
  }
  95% {
    clip: rect(38px, 9999px, 64px, 0);
  }
  100% {
    clip: rect(272px, 9999px, 248px, 0);
  }
}
@keyframes c1 {
  0% {
    clip: rect(47px, 9999px, 52px, 0);
  }
  5% {
    clip: rect(132px, 9999px, 189px, 0);
  }
  10% {
    clip: rect(176px, 9999px, 43px, 0);
  }
  15.000000000000002% {
    clip: rect(193px, 9999px, 163px, 0);
  }
  20% {
    clip: rect(304px, 9999px, 122px, 0);
  }
  25% {
    clip: rect(276px, 9999px, 153px, 0);
  }
  30.000000000000004% {
    clip: rect(207px, 9999px, 35px, 0);
  }
  35.00000000000001% {
    clip: rect(311px, 9999px, 152px, 0);
  }
  40% {
    clip: rect(301px, 9999px, 82px, 0);
  }
  45% {
    clip: rect(263px, 9999px, 37px, 0);
  }
  50% {
    clip: rect(300px, 9999px, 151px, 0);
  }
  55% {
    clip: rect(283px, 9999px, 9px, 0);
  }
  60.00000000000001% {
    clip: rect(133px, 9999px, 201px, 0);
  }
  65% {
    clip: rect(216px, 9999px, 211px, 0);
  }
  70.00000000000001% {
    clip: rect(124px, 9999px, 315px, 0);
  }
  75% {
    clip: rect(266px, 9999px, 287px, 0);
  }
  80% {
    clip: rect(281px, 9999px, 203px, 0);
  }
  85% {
    clip: rect(154px, 9999px, 182px, 0);
  }
  90% {
    clip: rect(265px, 9999px, 187px, 0);
  }
  95% {
    clip: rect(38px, 9999px, 64px, 0);
  }
  100% {
    clip: rect(272px, 9999px, 248px, 0);
  }
}
@-moz-keyframes c2 {
  0% {
    clip: rect(291px, 9999px, 92px, 0);
  }
  5% {
    clip: rect(307px, 9999px, 23px, 0);
  }
  10% {
    clip: rect(111px, 9999px, 190px, 0);
  }
  15.000000000000002% {
    clip: rect(113px, 9999px, 321px, 0);
  }
  20% {
    clip: rect(91px, 9999px, 276px, 0);
  }
  25% {
    clip: rect(232px, 9999px, 66px, 0);
  }
  30.000000000000004% {
    clip: rect(194px, 9999px, 143px, 0);
  }
  35.00000000000001% {
    clip: rect(79px, 9999px, 37px, 0);
  }
  40% {
    clip: rect(268px, 9999px, 102px, 0);
  }
  45% {
    clip: rect(237px, 9999px, 242px, 0);
  }
  50% {
    clip: rect(155px, 9999px, 178px, 0);
  }
  55% {
    clip: rect(108px, 9999px, 217px, 0);
  }
  60.00000000000001% {
    clip: rect(319px, 9999px, 310px, 0);
  }
  65% {
    clip: rect(36px, 9999px, 167px, 0);
  }
  70.00000000000001% {
    clip: rect(47px, 9999px, 147px, 0);
  }
  75% {
    clip: rect(15px, 9999px, 118px, 0);
  }
  80% {
    clip: rect(274px, 9999px, 115px, 0);
  }
  85% {
    clip: rect(112px, 9999px, 149px, 0);
  }
  90% {
    clip: rect(150px, 9999px, 319px, 0);
  }
  95% {
    clip: rect(25px, 9999px, 74px, 0);
  }
  100% {
    clip: rect(175px, 9999px, 97px, 0);
  }
  23% {
    transform: scaleX(0.8);
  }
}
@-webkit-keyframes c2 {
  0% {
    clip: rect(291px, 9999px, 92px, 0);
  }
  5% {
    clip: rect(307px, 9999px, 23px, 0);
  }
  10% {
    clip: rect(111px, 9999px, 190px, 0);
  }
  15.000000000000002% {
    clip: rect(113px, 9999px, 321px, 0);
  }
  20% {
    clip: rect(91px, 9999px, 276px, 0);
  }
  25% {
    clip: rect(232px, 9999px, 66px, 0);
  }
  30.000000000000004% {
    clip: rect(194px, 9999px, 143px, 0);
  }
  35.00000000000001% {
    clip: rect(79px, 9999px, 37px, 0);
  }
  40% {
    clip: rect(268px, 9999px, 102px, 0);
  }
  45% {
    clip: rect(237px, 9999px, 242px, 0);
  }
  50% {
    clip: rect(155px, 9999px, 178px, 0);
  }
  55% {
    clip: rect(108px, 9999px, 217px, 0);
  }
  60.00000000000001% {
    clip: rect(319px, 9999px, 310px, 0);
  }
  65% {
    clip: rect(36px, 9999px, 167px, 0);
  }
  70.00000000000001% {
    clip: rect(47px, 9999px, 147px, 0);
  }
  75% {
    clip: rect(15px, 9999px, 118px, 0);
  }
  80% {
    clip: rect(274px, 9999px, 115px, 0);
  }
  85% {
    clip: rect(112px, 9999px, 149px, 0);
  }
  90% {
    clip: rect(150px, 9999px, 319px, 0);
  }
  95% {
    clip: rect(25px, 9999px, 74px, 0);
  }
  100% {
    clip: rect(175px, 9999px, 97px, 0);
  }
  23% {
    transform: scaleX(0.8);
  }
}
@-o-keyframes c2 {
  0% {
    clip: rect(291px, 9999px, 92px, 0);
  }
  5% {
    clip: rect(307px, 9999px, 23px, 0);
  }
  10% {
    clip: rect(111px, 9999px, 190px, 0);
  }
  15.000000000000002% {
    clip: rect(113px, 9999px, 321px, 0);
  }
  20% {
    clip: rect(91px, 9999px, 276px, 0);
  }
  25% {
    clip: rect(232px, 9999px, 66px, 0);
  }
  30.000000000000004% {
    clip: rect(194px, 9999px, 143px, 0);
  }
  35.00000000000001% {
    clip: rect(79px, 9999px, 37px, 0);
  }
  40% {
    clip: rect(268px, 9999px, 102px, 0);
  }
  45% {
    clip: rect(237px, 9999px, 242px, 0);
  }
  50% {
    clip: rect(155px, 9999px, 178px, 0);
  }
  55% {
    clip: rect(108px, 9999px, 217px, 0);
  }
  60.00000000000001% {
    clip: rect(319px, 9999px, 310px, 0);
  }
  65% {
    clip: rect(36px, 9999px, 167px, 0);
  }
  70.00000000000001% {
    clip: rect(47px, 9999px, 147px, 0);
  }
  75% {
    clip: rect(15px, 9999px, 118px, 0);
  }
  80% {
    clip: rect(274px, 9999px, 115px, 0);
  }
  85% {
    clip: rect(112px, 9999px, 149px, 0);
  }
  90% {
    clip: rect(150px, 9999px, 319px, 0);
  }
  95% {
    clip: rect(25px, 9999px, 74px, 0);
  }
  100% {
    clip: rect(175px, 9999px, 97px, 0);
  }
  23% {
    transform: scaleX(0.8);
  }
}
@keyframes c2 {
  0% {
    clip: rect(291px, 9999px, 92px, 0);
  }
  5% {
    clip: rect(307px, 9999px, 23px, 0);
  }
  10% {
    clip: rect(111px, 9999px, 190px, 0);
  }
  15.000000000000002% {
    clip: rect(113px, 9999px, 321px, 0);
  }
  20% {
    clip: rect(91px, 9999px, 276px, 0);
  }
  25% {
    clip: rect(232px, 9999px, 66px, 0);
  }
  30.000000000000004% {
    clip: rect(194px, 9999px, 143px, 0);
  }
  35.00000000000001% {
    clip: rect(79px, 9999px, 37px, 0);
  }
  40% {
    clip: rect(268px, 9999px, 102px, 0);
  }
  45% {
    clip: rect(237px, 9999px, 242px, 0);
  }
  50% {
    clip: rect(155px, 9999px, 178px, 0);
  }
  55% {
    clip: rect(108px, 9999px, 217px, 0);
  }
  60.00000000000001% {
    clip: rect(319px, 9999px, 310px, 0);
  }
  65% {
    clip: rect(36px, 9999px, 167px, 0);
  }
  70.00000000000001% {
    clip: rect(47px, 9999px, 147px, 0);
  }
  75% {
    clip: rect(15px, 9999px, 118px, 0);
  }
  80% {
    clip: rect(274px, 9999px, 115px, 0);
  }
  85% {
    clip: rect(112px, 9999px, 149px, 0);
  }
  90% {
    clip: rect(150px, 9999px, 319px, 0);
  }
  95% {
    clip: rect(25px, 9999px, 74px, 0);
  }
  100% {
    clip: rect(175px, 9999px, 97px, 0);
  }
  23% {
    transform: scaleX(0.8);
  }
}
#console.container {
  width: 100%;
  height: 100vh;
  padding: 32px;
  font-size: 18px;
}
@media screen and (min-width: 767px) {
  #console.container {
    font-size: 24px;
  }
}
@media screen and (min-width: 1365px) {
  #console.container {
    padding: 100px;
  }
}
#console .hide {
  display: none;
}
.line {
  display: flex;
  align-items: center;
  position: relative;
}
.line--footer .prompt {
  color: rgba(255,255,255,0.5);
}
@media screen and (min-width: 960px) {
  .line--footer .prompt {
    position: absolute;
    top: 0;
    left: -1em;
  }
}
.line--footer .console-text::after {
  display: none;
}
.cursor {
  height: 1em;
  width: 0.5em;
  background: #1c17ff;
  animation: 1s linear infinite cursor;
}
@media screen and (min-width: 767px) {
  .cursor {
    margin-top: 1px;
  }
}
.cursor-animation {
  animation: 1s linear infinite cursor;
}
.prompt {
  color: #1c17ff;
  margin-right: 10px;
}
.console-text {
  min-width: 1px;
  min-height: 1em;
}
.console-text:after {
  content: '';
  height: 1em;
  width: 0.5em;
  background: #1c17ff;
  display: inline-block;
  position: absolute;
  animation: 1s linear infinite cursor;
}
.console-text--underscore::after {
  content: '_';
  background: none;
}
@-moz-keyframes cursor {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes cursor {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes cursor {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes cursor {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.menu {
  display: none;
  width: 100vh;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  transform-origin: 0 0;
  transform: rotate(-90deg);
  margin-top: 100vh;
  position: fixed;
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  top: 0;
  left: 0;
  padding: 30px 0;
  z-index: 20;
}
.menu > * {
  opacity: 0.5;
}
@media screen and (min-width: 767px) {
  .menu.show {
    display: flex;
  }
}
.menu.active .img {
  animation: fadeInRight 0.3s ease-in;
  opacity: 1;
}
.menu > * {
  flex: 1;
  transition: flex 0.3s ease-in, opacity 0.3s ease-in;
  overflow: hidden;
}
.menu .title {
  text-align: right;
}
.menu img {
  width: 30px;
  opacity: 0.5;
}
.menu .img {
  opacity: 0;
  flex-basis: 0;
  display: flex;
  align-items: center;
}
.menu .img:before {
  content: '';
  position: relative;
  top: -1px;
  width: 86px;
  height: 1px;
  opacity: 0.5;
  background-color: #fff;
  display: block;
  margin-left: 0px;
  margin-right: 0px;
  transition: all 0.3s;
}
.menu .links {
  flex: 0;
  opacity: 0;
  text-align: right;
  white-space: nowrap;
}
.menu .links a {
  color: #fff;
  opacity: 0.5;
  text-decoration: none;
  margin-left: 30px;
  transition: all 0.3s;
}
.menu .links a:hover,
.menu .links a:focus {
  opacity: 1;
}
.menu:hover .img a {
  color: #fff;
  text-decoration: none;
  transition: all 0.3s;
}
.menu:hover .img a:hover img,
.menu:hover .img a:focus img {
  opacity: 1;
}
.menu:hover .title {
  flex: 0;
  opacity: 0;
}
.menu:hover .links {
  flex: 1;
  flex-basis: 400px;
  opacity: 1;
}
.menu:hover .img:before {
  width: 16px;
  margin-left: 12px;
  margin-right: 12px;
  opacity: 1;
}
.mobile-menu-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  background: rgba(28,23,255,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10001;
  transition: opacity 0.16s;
}
@media screen and (min-width: 767px) {
  .mobile-menu-btn {
    display: none;
  }
}
.mobile-menu-btn.hide {
  pointer-events: none;
  opacity: 0;
}
.mobile-menu-btn span {
  width: 18px;
  height: 2px;
  background-color: #fff;
}
.mobile-menu-btn span:not(:last-child) {
  margin-bottom: 4px;
}
.mobile-menu-btn span:nth-child(1) {
  animation: firstSpanBw 0.48s ease-in-out forwards;
}
.mobile-menu-btn span:nth-child(2) {
  animation: secondSpanBw 0.48s ease-in-out forwards;
}
.mobile-menu-btn span:nth-child(3) {
  animation: thirdSpanBw 0.48s ease-in-out forwards;
}
.mobile-menu-btn.active span:nth-child(1) {
  animation: firstSpanFw 0.48s ease-in-out forwards;
}
.mobile-menu-btn.active span:nth-child(2) {
  animation: secondSpanFw 0.48s ease-in-out forwards;
}
.mobile-menu-btn.active span:nth-child(3) {
  animation: thirdSpanFw 0.48s ease-in-out forwards;
}
.mobile-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: #00022f;
  overflow: hidden;
  font-size: 24px;
  color: #fff;
  font-weight: bold;
  max-height: 0;
  transition: max-height 0.48s ease-in-out;
  will-change: max-height;
  display: flex;
  align-items: flex-end;
}
.mobile-menu::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background: #1c17ff;
  content: '';
  transition: transform 0.48s ease-in-out;
  transform-origin: 50% 0;
}
.mobile-menu nav {
  height: 100vh;
  padding: 76px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.mobile-menu img {
  position: absolute;
  left: 20px;
  bottom: 23px;
  height: 30px;
}
.mobile-menu.active {
  max-height: 100vh;
}
.mobile-menu.active:before {
  transform: scaleY(0);
}
.mobile-menu a {
  color: inherit;
  text-decoration: none;
  padding: 18px 20px;
}
@-moz-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes firstSpanFw {
  0% {
    transform: none;
  }
  50% {
    transform: translate3d(0, 6px, 0);
  }
  100% {
    transform: translate3d(0, 6px, 0) rotate(45deg);
  }
}
@-webkit-keyframes firstSpanFw {
  0% {
    transform: none;
  }
  50% {
    transform: translate3d(0, 6px, 0);
  }
  100% {
    transform: translate3d(0, 6px, 0) rotate(45deg);
  }
}
@-o-keyframes firstSpanFw {
  0% {
    transform: none;
  }
  50% {
    transform: translate3d(0, 6px, 0);
  }
  100% {
    transform: translate3d(0, 6px, 0) rotate(45deg);
  }
}
@keyframes firstSpanFw {
  0% {
    transform: none;
  }
  50% {
    transform: translate3d(0, 6px, 0);
  }
  100% {
    transform: translate3d(0, 6px, 0) rotate(45deg);
  }
}
@-moz-keyframes secondSpanFw {
  0% {
    transform: none;
  }
  50% {
    transform: none;
  }
  100% {
    transform: rotate(-45deg);
  }
}
@-webkit-keyframes secondSpanFw {
  0% {
    transform: none;
  }
  50% {
    transform: none;
  }
  100% {
    transform: rotate(-45deg);
  }
}
@-o-keyframes secondSpanFw {
  0% {
    transform: none;
  }
  50% {
    transform: none;
  }
  100% {
    transform: rotate(-45deg);
  }
}
@keyframes secondSpanFw {
  0% {
    transform: none;
  }
  50% {
    transform: none;
  }
  100% {
    transform: rotate(-45deg);
  }
}
@-moz-keyframes thirdSpanFw {
  0% {
    transform: none;
  }
  50% {
    transform: translate3d(0, -6px, 0);
  }
  100% {
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }
}
@-webkit-keyframes thirdSpanFw {
  0% {
    transform: none;
  }
  50% {
    transform: translate3d(0, -6px, 0);
  }
  100% {
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }
}
@-o-keyframes thirdSpanFw {
  0% {
    transform: none;
  }
  50% {
    transform: translate3d(0, -6px, 0);
  }
  100% {
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }
}
@keyframes thirdSpanFw {
  0% {
    transform: none;
  }
  50% {
    transform: translate3d(0, -6px, 0);
  }
  100% {
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }
}
@-moz-keyframes firstSpanBw {
  0% {
    transform: translate3d(0, 6px, 0) rotate(45deg);
  }
  50% {
    transform: translate3d(0, 6px, 0);
  }
  100% {
    transform: none;
  }
}
@-webkit-keyframes firstSpanBw {
  0% {
    transform: translate3d(0, 6px, 0) rotate(45deg);
  }
  50% {
    transform: translate3d(0, 6px, 0);
  }
  100% {
    transform: none;
  }
}
@-o-keyframes firstSpanBw {
  0% {
    transform: translate3d(0, 6px, 0) rotate(45deg);
  }
  50% {
    transform: translate3d(0, 6px, 0);
  }
  100% {
    transform: none;
  }
}
@keyframes firstSpanBw {
  0% {
    transform: translate3d(0, 6px, 0) rotate(45deg);
  }
  50% {
    transform: translate3d(0, 6px, 0);
  }
  100% {
    transform: none;
  }
}
@-moz-keyframes secondSpanBw {
  0% {
    transform: rotate(-45deg);
  }
  50% {
    transform: none;
  }
  100% {
    transform: none;
  }
}
@-webkit-keyframes secondSpanBw {
  0% {
    transform: rotate(-45deg);
  }
  50% {
    transform: none;
  }
  100% {
    transform: none;
  }
}
@-o-keyframes secondSpanBw {
  0% {
    transform: rotate(-45deg);
  }
  50% {
    transform: none;
  }
  100% {
    transform: none;
  }
}
@keyframes secondSpanBw {
  0% {
    transform: rotate(-45deg);
  }
  50% {
    transform: none;
  }
  100% {
    transform: none;
  }
}
@-moz-keyframes thirdSpanBw {
  0% {
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }
  50% {
    transform: translate3d(0, -6px, 0);
  }
  100% {
    transform: none;
  }
}
@-webkit-keyframes thirdSpanBw {
  0% {
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }
  50% {
    transform: translate3d(0, -6px, 0);
  }
  100% {
    transform: none;
  }
}
@-o-keyframes thirdSpanBw {
  0% {
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }
  50% {
    transform: translate3d(0, -6px, 0);
  }
  100% {
    transform: none;
  }
}
@keyframes thirdSpanBw {
  0% {
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }
  50% {
    transform: translate3d(0, -6px, 0);
  }
  100% {
    transform: none;
  }
}
header {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  align-items: center;
  z-index: 5;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
@media screen and (min-width: 767px) {
  header {
    margin: 40px;
  }
}
@media screen and (min-width: 1365px) {
  header {
    margin: 50px;
  }
}
header img {
  height: 24px;
}
@media screen and (min-width: 767px) {
  header img {
    height: 30px;
  }
}
@media screen and (min-width: 1365px) {
  header img {
    height: 40px;
  }
}
@media screen and (min-width: 1839px) {
  header img {
    height: 44px;
  }
}
header .social {
  display: flex;
}
header .social a {
  height: 20px;
  width: 20px;
  margin: 0 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
@media screen and (min-width: 767px) {
  header .social a {
    height: 40px;
    width: 40px;
    margin: 0 5px;
  }
}
@media screen and (min-width: 1365px) {
  header .social a {
    height: 44px;
    width: 44px;
    margin: 0 12px;
  }
}
header .social a svg > * {
  fill: #fff;
}
header .social a:hover,
header .social a:focus {
  opacity: 0.8;
  transform: scale(1.4);
}
#dudeCanvasContainer,
#projectorGuy,
#buttonGlitch {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  pointer-events: none;
}
#dudeCanvasContainer canvas,
#projectorGuy canvas,
#buttonGlitch canvas {
  position: absolute;
  top: 0;
  left: 0;
}
#dudeCanvasContainer canvas,
#projectorGuy canvas,
#feedbackCanvas canvas {
  z-index: 3;
}
#dudeCanvasContainer::after,
#projectorGuy::after,
#feedbackCanvas::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  opacity: 1;
  background: url("a5710a6a888cfe6d17373299bf3ecd93.png") center/contain no-repeat;
  z-index: 2;
}
#buttonGlitch {
  pointer-events: none;
  position: fixed;
  left: 0;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  #buttonGlitch {
    display: none;
  }
}
#dudeCanvasContainer {
  margin-left: -20px;
  margin-top: 120px;
  height: 415px;
  z-index: -1;
}
@media screen and (min-width: 767px) {
  #dudeCanvasContainer {
    margin-top: 300px;
    margin-left: -20px;
    height: 100vh;
  }
}
@media screen and (min-width: 1365px) {
  #dudeCanvasContainer {
    margin-top: 0;
    margin-left: 20%;
    -webkit-mask-image: linear-gradient(to right, rgba(0,0,0,0) 0, #000 400px);
    mask-image: linear-gradient(to right, rgba(0,0,0,0) 0, #000 400px);
  }
}
#main {
  padding-top: 90px;
  margin-bottom: 67px;
  position: relative;
}
#main .down-icon {
  display: none;
}
@media screen and (min-width: 767px) {
  #main {
    margin-bottom: 350px;
    padding-top: 160px;
  }
}
@media screen and (min-width: 1365px) {
  #main {
    margin-bottom: 0;
    padding-top: 160px;
    min-height: 100vh;
  }
  #main .down-icon {
    display: flex;
  }
}
@media screen and (min-width: 1839px) {
  #main {
    padding-top: 151px;
  }
}
.text {
  position: relative;
  z-index: 1;
  font-family: Consolas;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  width: 100%;
  text-align: left;
}
@media screen and (min-width: 767px) {
  .text {
    text-align: center;
  }
}
@media screen and (min-width: 1365px) {
  .text {
    font-size: 18px;
    width: 50%;
    text-align: left;
  }
}
@media screen and (min-width: 1839px) {
  .text {
    font-size: 24px;
    width: 60%;
  }
}
h1 {
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 100%;
  margin-bottom: 310px;
  text-align: center;
}
@media screen and (min-width: 767px) {
  h1 {
    font-size: 64px;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1365px) {
  h1 {
    font-size: 88px;
    margin-bottom: 24px;
    text-align: left;
  }
}
@media screen and (min-width: 1839px) {
  h1 {
    font-size: 112px;
    margin-bottom: 48px;
  }
}
.with-tags {
  margin-bottom: 20px;
}
.with-tags p:before {
  content: '<p>';
  color: #f93c47;
}
.with-tags p:after {
  content: '</p>';
  color: #f93c47;
}
.with-tags i:before {
  content: '<br>';
  color: #f93c47;
}
@media screen and (min-width: 767px) {
  .with-tags {
    margin-bottom: 470px;
  }
}
@media screen and (min-width: 1365px) {
  .with-tags {
    margin-bottom: 32px;
  }
}
@media screen and (min-width: 1839px) {
  .with-tags {
    margin-bottom: 48px;
  }
}
.down-icon {
  display: flex;
  justify-content: center;
  margin: 70px 0;
}
.down-icon:after {
  content: '';
  background: url("141a40c9010fe16979f744cb9ead42d0.svg");
  width: 24px;
  height: 12px;
  background-size: 24px;
  background-repeat: no-repeat;
  display: block;
}
.dg.ac {
  z-index: 1000 !important;
}
#video {
  margin-bottom: 80px;
  min-height: inherit;
}
.video {
  max-width: 100%;
  width: 100vw;
  height: 260px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 767px) {
  .video {
    height: 387px;
  }
}
@media screen and (min-width: 1365px) {
  .video {
    height: 655px;
  }
}
@media screen and (min-width: 1839px) {
  .video {
    height: 80vh;
  }
}
.video canvas,
.video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.video canvas {
  pointer-events: none;
  transition: opacity 0.16s;
}
.play-button {
  cursor: pointer;
  border: none;
  z-index: 2;
  background: none;
  outline: none;
  transition: opacity 0.16s;
  width: 200px;
  height: 101px;
}
@media screen and (min-width: 767px) {
  .play-button {
    width: 453px;
    height: 229px;
  }
}
#video.active .play-button,
#video.active canvas {
  pointer-events: none;
  opacity: 0;
}
.marquee {
  position: absolute;
  overflow: hidden;
  z-index: -1;
  bottom: -20px;
  left: -20px;
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 100%;
  text-transform: uppercase;
  color: #000115;
  -webkit-text-stroke: 1px #fff;
  -webkit-text-fill-color: #000115;
}
@media screen and (min-width: 767px) {
  .marquee {
    font-size: 80px;
    bottom: -60px;
    left: -40px;
  }
}
@media screen and (min-width: 1365px) {
  .marquee {
    font-size: 160px;
    left: -100px;
  }
}
@media screen and (min-width: 1839px) {
  .marquee {
    left: -200px;
  }
}
.marquee__inner {
  display: flex;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  transform: translate3d(calc(-25% + 10vw), 0, 0);
  animation: marquee 5s linear infinite;
}
.marquee__inner span {
  padding: 0 2vw;
}
.marquee.right {
  bottom: -80px;
}
.marquee.right .marquee__inner {
  transform: translate3d(calc(-50% + 10vw), 0, 0);
  animation: marqueeRight 10s linear infinite;
}
@media screen and (min-width: 767px) {
  .marquee.right {
    bottom: -220px;
  }
}
@-moz-keyframes marquee {
  0% {
    transform: translate3d(calc(-25% + 10vw), 0, 0);
  }
  100% {
    transform: translate3d(calc(-50% + 10vw), 0, 0);
  }
}
@-webkit-keyframes marquee {
  0% {
    transform: translate3d(calc(-25% + 10vw), 0, 0);
  }
  100% {
    transform: translate3d(calc(-50% + 10vw), 0, 0);
  }
}
@-o-keyframes marquee {
  0% {
    transform: translate3d(calc(-25% + 10vw), 0, 0);
  }
  100% {
    transform: translate3d(calc(-50% + 10vw), 0, 0);
  }
}
@keyframes marquee {
  0% {
    transform: translate3d(calc(-25% + 10vw), 0, 0);
  }
  100% {
    transform: translate3d(calc(-50% + 10vw), 0, 0);
  }
}
@-moz-keyframes marqueeRight {
  0% {
    transform: translate3d(calc(-50% + 10vw), 0, 0);
  }
  100% {
    transform: translate3d(calc(-25% + 10vw), 0, 0);
  }
}
@-webkit-keyframes marqueeRight {
  0% {
    transform: translate3d(calc(-50% + 10vw), 0, 0);
  }
  100% {
    transform: translate3d(calc(-25% + 10vw), 0, 0);
  }
}
@-o-keyframes marqueeRight {
  0% {
    transform: translate3d(calc(-50% + 10vw), 0, 0);
  }
  100% {
    transform: translate3d(calc(-25% + 10vw), 0, 0);
  }
}
@keyframes marqueeRight {
  0% {
    transform: translate3d(calc(-50% + 10vw), 0, 0);
  }
  100% {
    transform: translate3d(calc(-25% + 10vw), 0, 0);
  }
}
#about {
  position: relative;
}
#about .text {
  padding-top: 400px;
  padding-bottom: 100px;
  margin-left: 0;
  text-align: left;
}
#about .text .with-tags {
  margin-bottom: 30px;
}
@media screen and (min-width: 767px) {
  #about .text {
    padding-top: 650px;
    padding-bottom: 100px;
    margin-left: 35px;
  }
}
@media screen and (min-width: 1365px) {
  #about .text {
    margin-left: 48%;
    width: inherit;
    padding: 250px 0;
  }
}
#about #projectorGuy {
  height: 421px;
  top: 0;
}
@media screen and (min-width: 767px) {
  #about #projectorGuy {
    height: 700px;
  }
}
@media screen and (min-width: 1365px) {
  #about #projectorGuy {
    top: 120px;
    right: 20%;
  }
}
@media screen and (min-width: 1839px) {
  #about #projectorGuy {
    height: 100%;
  }
}
#about h1 {
  margin-bottom: 0;
  font-family: Consolas;
  font-style: normal;
  font-weight: normal;
  text-transform: lowercase;
}
#about .console {
  margin-bottom: 25px;
  font-family: Consolas;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 100%;
  position: relative;
  left: 0;
}
#about .console .cursor {
  width: 13px;
  height: 21px;
}
@media screen and (min-width: 767px) {
  #about .console .cursor {
    width: 26px;
    height: 43px;
  }
}
@media screen and (min-width: 1365px) {
  #about .console .cursor {
    width: 31px;
    height: 49px;
  }
}
@media screen and (min-width: 1839px) {
  #about .console .cursor {
    width: 43px;
    height: 72px;
  }
}
@media screen and (min-width: 767px) {
  #about .console {
    font-size: 64px;
    left: -50px;
  }
}
@media screen and (min-width: 1365px) {
  #about .console {
    font-size: 72px;
    left: -60px;
  }
}
@media screen and (min-width: 1839px) {
  #about .console {
    font-size: 96px;
    left: -70px;
  }
}
#about .console:before {
  margin-right: 20px;
}
.slider {
  width: 100vw;
  overflow: hidden;
  position: relative;
  margin-bottom: 50px;
  height: initial;
  z-index: 2;
  margin-left: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 767px) {
  .slider {
    margin-bottom: 0;
    margin-left: -40px;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 1365px) {
  .slider {
    margin-bottom: 150px;
    margin-left: -100px;
  }
}
@media screen and (min-width: 1839px) {
  .slider {
    height: 100vh;
    margin-left: -200px;
  }
}
.slider .siema > * > * {
  position: relative;
  overflow: hidden;
}
.slider .slide {
  width: calc(100vw - 40px);
  display: flex;
  flex-direction: column;
  padding: 105px 20px 0 20px;
  box-sizing: content-box;
}
@media screen and (min-width: 767px) {
  .slider .slide {
    width: calc(100vw - 80px);
    padding: 140px 40px;
  }
}
@media screen and (min-width: 1365px) {
  .slider .slide {
    width: calc(100vw - 200px);
    padding: 140px 100px;
  }
}
@media screen and (min-width: 1839px) {
  .slider .slide {
    width: calc(100vw - 400px);
    padding: 140px 200px;
  }
}
.slider .slide .count {
  position: absolute;
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 110px;
  line-height: 100%;
  text-align: center;
  -webkit-text-stroke: 4px #fff;
  -webkit-text-fill-color: #fff;
  margin-top: 0;
  padding-left: 14px;
  right: 20px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 767px) {
  .slider .slide .count {
    font-size: 160px;
    left: initial;
    text-align: right;
    right: 40px;
  }
}
@media screen and (min-width: 1365px) {
  .slider .slide .count {
    top: initial;
    margin-top: -52px;
    font-size: 200px;
    right: 100px;
  }
}
@media screen and (min-width: 1839px) {
  .slider .slide .count {
    font-size: 300px;
    margin-top: -102px;
    right: 200px;
  }
}
.slider .slide .count:before {
  content: '';
  position: absolute;
  display: none;
  width: 100vw;
  height: 5px;
  background-color: #1c17ff;
  right: 100%;
  top: 148px;
  margin-left: 74px;
}
@media screen and (min-width: 1365px) {
  .slider .slide .count:before {
    display: inline-block;
    top: 98px;
  }
}
@media screen and (min-width: 1839px) {
  .slider .slide .count:before {
    top: 148px;
  }
}
.slider .slide .count span {
  display: inline-block;
}
.slider .slide .count span:nth-child(1) {
  z-index: 3;
  position: relative;
}
.slider .slide .count span:nth-child(2),
.slider .slide .count span:nth-child(3) {
  position: absolute;
  color: #000115;
  -webkit-text-stroke: 3px #fff;
  -webkit-text-fill-color: transparent;
}
.slider .slide .count span:nth-child(2) {
  z-index: 1;
  top: 50px;
  opacity: 0.75;
  filter: blur(15px);
}
.slider .slide .count span:nth-child(3) {
  z-index: 0;
  top: 107px;
  opacity: 0.5;
  filter: blur(25px);
}
.slider .slide .text {
  width: 100%;
  margin: 0;
  padding-bottom: 40px;
}
@media screen and (min-width: 767px) {
  .slider .slide .text {
    width: 680px;
    padding-bottom: 0;
  }
}
.slider .slide .with-tags {
  text-align: left;
}
@media screen and (min-width: 767px) {
  .slider .slide .with-tags {
    margin-bottom: 0;
    text-align: left;
  }
}
.slider .slide h3 {
  font-family: Consolas;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 100%;
  margin-top: 5px;
  margin-bottom: 28px;
  position: relative;
  display: flex;
  width: inherit;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 767px) {
  .slider .slide h3 {
    justify-content: initial;
    width: 100vw;
    font-size: 32px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}
@media screen and (min-width: 1365px) {
  .slider .slide h3 {
    font-size: 48px;
    margin-bottom: 40px;
    margin-top: 25px;
  }
}
@media screen and (min-width: 1839px) {
  .slider .slide h3 {
    margin-bottom: 64px;
  }
}
.slider .slide h3 span {
  flex-shrink: 1;
  position: relative;
}
@media screen and (min-width: 767px) {
  .slider .slide h3 span {
    flex-shrink: 0;
  }
}
@media screen and (min-width: 1365px) {
  .slider .slide h3 span:before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    background: #000115;
    z-index: -1;
    right: -38px;
  }
}
.slider .buttons {
  position: absolute;
  bottom: 0;
  right: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  z-index: 2;
}
@media screen and (min-width: 767px) {
  .slider .buttons {
    position: absolute;
    top: 165px;
    right: 40px;
    left: initial;
  }
}
@media screen and (min-width: 1365px) {
  .slider .buttons {
    top: initial;
    bottom: 20px;
    right: 100px;
  }
}
@media screen and (min-width: 1839px) {
  .slider .buttons {
    right: 200px;
  }
}
.slider .buttons .num {
  margin-right: auto;
}
@media screen and (min-width: 767px) {
  .slider .buttons .num {
    margin-right: 40px;
  }
}
.slider .buttons button {
  display: flex;
  position: relative;
  border: 1px solid #fff;
  background: transparent;
  height: 44px;
  width: 44px;
  margin: 0 12px;
  cursor: pointer;
  transition: all 0.3s;
  justify-content: center;
  align-items: center;
}
.slider .buttons button:hover {
  background: #1c17ff;
  border: 1px solid #1c17ff;
}
.slider .buttons button .prev:after,
.slider .buttons button .next:after {
  content: '';
  background: url("141a40c9010fe16979f744cb9ead42d0.svg");
  width: 13px;
  height: 7px;
  background-size: 13px;
  background-repeat: no-repeat;
  display: block;
  transform: rotate(90deg);
}
.slider .buttons button .next:after {
  transform: rotate(-90deg);
}
#counts {
  position: relative;
}
.counts-triangle {
  position: absolute;
}
.counts-triangle:nth-child(1) {
  top: -70px;
  width: 320px;
  right: 170px;
  z-index: 1;
}
@media screen and (min-width: 767px) {
  .counts-triangle:nth-child(1) {
    top: -70px;
    width: 320px;
  }
}
@media screen and (min-width: 1365px) {
  .counts-triangle:nth-child(1) {
    z-index: 3;
    width: 520px;
  }
}
@media screen and (min-width: 1839px) {
  .counts-triangle:nth-child(1) {
    top: 0;
    z-index: 3;
    width: initial;
  }
}
.counts-triangle:nth-child(2) {
  top: 0;
  width: 190px;
  right: 0px;
  z-index: 1;
}
@media screen and (min-width: 767px) {
  .counts-triangle:nth-child(2) {
    top: 0;
    width: 190px;
  }
}
@media screen and (min-width: 1365px) {
  .counts-triangle:nth-child(2) {
    width: 290px;
  }
}
@media screen and (min-width: 1839px) {
  .counts-triangle:nth-child(2) {
    top: 350px;
    width: initial;
  }
}
h2 {
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  margin-bottom: 24px;
}
@media screen and (min-width: 767px) {
  h2 {
    font-size: 56px;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1365px) {
  h2 {
    font-size: 64px;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1839px) {
  h2 {
    font-size: 88px;
    margin-bottom: 50px;
  }
}
#vacancy {
  margin-bottom: 0;
  position: relative;
  min-height: 0;
}
@media screen and (min-width: 767px) {
  #vacancy {
    margin-bottom: 300px;
  }
}
#vacancy .marquee-block {
  left: -20px;
}
@media screen and (min-width: 767px) {
  #vacancy .marquee-block {
    left: -40px;
  }
}
@media screen and (min-width: 1365px) {
  #vacancy .marquee-block {
    left: -100px;
  }
}
@media screen and (min-width: 1839px) {
  #vacancy .marquee-block {
    left: -200px;
  }
}
.vacancy-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
}
@media screen and (min-width: 767px) {
  .vacancy-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1365px) {
  .vacancy-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
.vacancy-item {
  padding: 20px;
  font-family: Consolas;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  position: relative;
  backdrop-filter: blur(20px);
}
.vacancy-item > * {
  position: relative;
  z-index: 3;
}
@media screen and (min-width: 767px) and (max-width: 1365px) {
  .vacancy-item:nth-child(2n + 2) {
    top: 60px;
  }
}
@media screen and (min-width: 1365px) {
  .vacancy-item:nth-child(3n + 2) {
    top: 60px;
  }
  .vacancy-item:nth-child(3n + 3) {
    top: 120px;
  }
}
@media screen and (min-width: 1839px) {
  .vacancy-item {
    font-size: 18px;
    padding: 40px;
  }
}
.vacancy-item .title {
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 20px;
}
.vacancy-item .exp,
.vacancy-item .desc {
  margin-bottom: 20px;
  opacity: 0.6;
}
.vacancy-item::after {
  background: rgba(0,41,255,0.5);
  backdrop-filter: blur(20px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  z-index: 0;
}
#contact {
  margin-bottom: 0px;
  position: relative;
}
@media screen and (min-width: 1365px) {
  #contact {
    margin-bottom: 100px;
  }
}
#contact h2 {
  text-align: left;
  margin-bottom: 48px;
  padding-top: 150px;
}
@media screen and (min-width: 1365px) {
  #contact h2 {
    padding-top: 0;
  }
}
#contact .line {
  align-items: center;
}
#contact .loader {
  margin-top: 0px;
}
form {
  margin-bottom: 58px;
  position: relative;
  z-index: 10;
}
form button {
  margin-top: 10px;
}
.message {
  padding: 13px 12px;
  margin-bottom: 20px;
  border: 1px dashed #ff0199;
  font-family: Consolas;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  color: #ff007a;
  text-shadow: 0px 0px 10px #ff0199;
  width: 100%;
}
@media screen and (min-width: 767px) {
  .message {
    padding: 13px 20px;
    width: 570px;
    font-size: 18px;
  }
}
@media screen and (min-width: 1365px) {
  .message {
    width: 540px;
    font-size: 20px;
  }
}
@media screen and (min-width: 1839px) {
  .message {
    width: 620px;
    font-size: 24px;
  }
}
.message-title {
  text-shadow: 0px 0px 10px #ff0199;
}
.message-text {
  font-weight: normal;
  text-shadow: 0px 0px 10px #ff0199;
}
.field {
  border: 1px solid #fff;
  padding: 11px 12px;
  font-family: Consolas;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  white-space: nowrap;
}
@media screen and (min-width: 767px) {
  .field {
    font-size: 18px;
    padding: 15px 20px;
    width: 570px;
  }
}
@media screen and (min-width: 1365px) {
  .field {
    width: 540px;
  }
}
@media screen and (min-width: 1839px) {
  .field {
    width: 620px;
    font-size: 24px;
    padding: 20px;
  }
}
.field .label {
  color: #6ba6ff;
  margin-right: 10px;
  cursor: pointer;
  flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
}
.field input {
  border: none;
  background: transparent;
  color: #fff;
  font-family: Consolas;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  font-size: 16px;
  flex: 1 0 0%;
}
@media screen and (min-width: 767px) {
  .field input {
    font-size: 24px;
  }
}
.field input:focus {
  outline: none;
}
.send:after {
  background: url("58295394c515cab6d97c7bc80c1e3112.svg");
  width: 34px;
  height: 34px;
  background-size: 34px;
  background-repeat: no-repeat;
}
.file:after {
  background: url("30df1ef7c31db2459b2ede7855bc6db2.svg");
  width: 24px;
  height: 24px;
  background-size: 23px;
  background-repeat: no-repeat;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: transparent;
  background-image: none;
  flex: 0 0 auto;
  font-family: Consolas;
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  line-height: 100%;
  color: #fff;
  cursor: pointer;
  padding-right: 40px;
  text-align: right;
}
select::-ms-expand {
  display: none;
}
span.icon {
  display: flex;
  align-items: center;
  height: 100%;
}
.choices {
  flex: 1 0 0%;
  margin-right: -1px;
}
.select-input {
  position: relative;
  z-index: 10;
  padding-right: 0;
}
.select-input .down-icon {
  margin: 0;
  position: absolute;
  right: 20px;
  z-index: -1;
}
.select-input .down-icon:after {
  width: 18px;
  height: 9px;
  background-size: 18px;
}
.file-input .label {
  display: flex;
  width: 100%;
  margin-right: 0;
  align-items: center;
}
.file-input input[type="file"] {
  display: none;
}
.file-input .icon {
  margin-left: 10px;
  flex: 1 0 0%;
  width: 0;
}
.file-input .icon span {
  color: #fff;
  font-style: italic;
  margin-right: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-input .icon::after {
  flex: 0 0 auto;
}
#feedbackCanvas {
  width: 100vw;
  height: 100vw;
  pointer-events: none;
}
#feedbackCanvas canvas {
  margin-left: -20px;
}
@media screen and (min-width: 1365px) {
  #feedbackCanvas {
    bottom: -100px;
    top: -120px;
    left: 0;
    right: -200px;
    position: absolute;
    width: auto;
    height: auto;
    overflow: hidden;
  }
  #feedbackCanvas canvas {
    transform: translate3d(250px, 100px, 0);
  }
}
.response {
  display: none;
}
.response.show {
  display: block;
}
.response .loader {
  display: none;
}
.response.loading .loader {
  display: block;
}
.response .message {
  display: none;
}
.response.success .message,
.response.error .message {
  display: block;
}
.response.success .message {
  border: 1px dashed #bcf79d;
  color: #80e64b;
  text-shadow: 0px 0px 10px #bcf79d;
}
.response.success .message-title {
  text-shadow: 0px 0px 10px #bcf79d;
}
.response.success .message-text {
  text-shadow: 0px 0px 10px #bcf79d;
}
.response .progress {
  overflow: hidden;
}
.response .progress-bg {
  animation: 2s linear infinite loader forwards;
}
@-moz-keyframes loader {
  0% {
    left: -30%;
  }
  100% {
    left: 100%;
  }
}
@-webkit-keyframes loader {
  0% {
    left: -30%;
  }
  100% {
    left: 100%;
  }
}
@-o-keyframes loader {
  0% {
    left: -30%;
  }
  100% {
    left: 100%;
  }
}
@keyframes loader {
  0% {
    left: -30%;
  }
  100% {
    left: 100%;
  }
}
.choices {
  position: relative;
}
.choices:focus {
  outline: none;
}
.choices:last-child {
  margin-bottom: 0;
}
.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #eaeaea;
  cursor: not-allowed;
  user-select: none;
}
.choices.is-disabled .choices__item {
  cursor: not-allowed;
}
.choices [hidden] {
  display: none !important;
}
.choices[data-type*='select-one'] {
  cursor: pointer;
}
.choices[data-type*='select-one'] .choices__button {
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: 0.5;
}
.choices[data-type*='select-one'] .choices__button:hover,
.choices[data-type*='select-one'] .choices__button:focus {
  opacity: 1;
}
.choices[data-type*='select-one'] .choices__button:focus {
  box-shadow: 0px 0px 0px 2px #00bcd4;
}
.choices[data-type*='select-one'] .choices__item[data-value=''] .choices__button {
  display: none;
}
.choices[data-type*='select-one'][dir='rtl']:after {
  left: 11.5px;
  right: auto;
}
.choices[data-type*='select-one'][dir='rtl'] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}
.choices[data-type*='select-multiple'] .choices__inner,
.choices[data-type*='text'] .choices__inner {
  cursor: text;
}
.choices[data-type*='select-multiple'] .choices__button,
.choices[data-type*='text'] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -$choices-button-offset/2;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 16px;
  border-left: 1px solid #00a9bf;
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0;
}
.choices[data-type*='select-multiple'] .choices__button:hover,
.choices[data-type*='text'] .choices__button:hover,
.choices[data-type*='select-multiple'] .choices__button:focus,
.choices[data-type*='text'] .choices__button:focus {
  opacity: 1;
}
.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  overflow: hidden;
}
.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%;
}
[dir='rtl'] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}
.choices__list--single .choices__item {
  width: 100%;
}
.choices__list--multiple {
  display: inline;
}
.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 24px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #00bcd4;
  border: 1px solid #00b3c9;
  color: #fff;
  word-break: break-all;
  box-sizing: border-box;
}
.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}
[dir='rtl'] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}
.choices__list--multiple .choices__item.is-highlighted {
  background: #fff;
  color: #02005f;
}
.is-disabled .choices__list--multiple .choices__item {
  background-color: #b0b0b0;
  border: 1px solid #989898;
}
.choices__list--dropdown {
  pointer-events: none;
  transform: translate3d(0, -10px, 0);
  opacity: 0;
  transition: opacity 0.16s ease-in-out, transform 0.16s ease-in-out;
  z-index: 1;
  position: absolute;
  width: 100%;
  background: #02005f;
  border: 1px solid #fff;
  top: 100%;
  margin-top: 31px;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;
}
.choices__list--dropdown.is-active {
  pointer-events: auto;
  transform: translate3d(0, 0px, 0);
  opacity: 1;
}
.is-open .choices__list--dropdown {
  border-color: #bcbcbc;
}
.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: 0.25rem 0.25rem 0 0;
}
.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}
.choices__list--dropdown .choices__item {
  position: relative;
  padding: 10px;
  font-size: $choices-font-size-md;
}
[dir='rtl'] .choices__list--dropdown .choices__item {
  text-align: right;
}
@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable {
    padding-right: 100px;
  }
  .choices__list--dropdown .choices__item--selectable:after {
    content: attr(data-select-text);
    font-size: 24px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  [dir='rtl'] .choices__list--dropdown .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }
  [dir='rtl'] .choices__list--dropdown .choices__item--selectable:after {
    right: auto;
    left: 10px;
  }
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background: #fff;
  color: #02005f;
}
.choices__item {
  cursor: default;
}
.choices__item--selectable {
  cursor: pointer;
}
.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
}
.choices__heading {
  font-weight: 600;
  font-size: 24px;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  color: #707070;
}
.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.choices__button:focus {
  outline: none;
}
.choices__input {
  display: none;
}
.choices__placeholder {
  opacity: 0.5;
}
.footer {
  background: #2522d5;
  position: relative;
  padding: 56px 20px 50px;
}
@media screen and (min-width: 415px) {
  .footer {
    padding: 55px 20px 34px;
  }
}
@media screen and (min-width: 640px) {
  .footer {
    padding: 110px 40px 100px;
  }
}
@media screen and (min-width: 960px) {
  .footer {
    padding: 110px 100px 110px;
  }
}
@media screen and (min-width: 1280px) {
  .footer {
    padding: 110px 100px 115px;
  }
}
@media screen and (min-width: 1600px) {
  .footer {
    padding: 110px 200px 90px;
  }
}
.footer__row {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 960px) {
  .footer__row {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  .footer__row > * {
    flex: 0 0 50%;
  }
}
.footer .button {
  font-size: 22px;
}
@media screen and (max-width: 766px) {
  .footer .button span {
    justify-content: center;
  }
}
@media screen and (min-width: 640px) {
  .footer .button {
    font-size: 32px;
  }
}
@media screen and (min-width: 960px) {
  .footer .button {
    font-size: 28px;
  }
}
@media screen and (min-width: 1280px) {
  .footer .button {
    font-size: 32px;
  }
}
.footer__animate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  margin-top: -20px;
  margin-bottom: -20px;
}
.footer .contact-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 10px;
}
@media screen and (min-width: 767px) {
  .footer .contact-block {
    width: 620px;
    flex-direction: row;
    margin-bottom: 0;
  }
}
.footer .contact-item {
  margin-bottom: 40px;
}
@media screen and (min-width: 415px) {
  .footer .contact-item {
    margin-bottom: 32px;
  }
}
@media screen and (min-width: 640px) {
  .footer .contact-item {
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 960px) {
  .footer .contact-item {
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 1280px) {
  .footer .contact-item {
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 1600px) {
  .footer .contact-item {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 640px) and (max-width: 959px) {
  .footer .contact-item--button {
    align-self: center;
  }
}
.footer .title {
  font-family: Consolas;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  color: rgba(255,255,255,0.6);
  font-size: 18px;
  margin-bottom: 8px;
}
@media screen and (min-width: 415px) {
  .footer .title {
    font-size: 20px;
  }
}
@media screen and (min-width: 640px) {
  .footer .title {
    font-size: 24px;
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 1600px) {
  .footer .title {
    font-size: 28px;
  }
}
.footer a.value {
  font-family: Consolas;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 100%;
  color: #fff;
  opacity: 1;
  transition: all 0.3s;
  text-decoration: none;
}
.footer a.value:hover,
.footer a.value:focus {
  opacity: 0.8;
}
@media screen and (min-width: 415px) {
  .footer a.value {
    font-size: 32px;
  }
}
@media screen and (min-width: 640px) {
  .footer a.value {
    font-size: 48px;
  }
}
@media screen and (min-width: 960px) {
  .footer a.value {
    font-size: 36px;
  }
}
@media screen and (min-width: 1280px) {
  .footer a.value {
    font-size: 48px;
  }
}
@media screen and (min-width: 1600px) {
  .footer a.value {
    font-size: 56px;
  }
}
body {
  background: #000115;
  color: #fff;
  font-family: Consolas;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 140%;
}
